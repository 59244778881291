.sidebar{ position: fixed; background:#313A52; position: fixed; top: 0; left: 0; width: 72px; height: 100%; z-index: 999; overflow-y: auto; }

.sidebar .logo{ width: 40px; margin-left: 15px; margin-top: 32px; margin-bottom: 20px; }
.sidebar .logo img{ width: 100%; }
.sidebar .logo img:hover{ cursor: pointer; }

.sidebar ul li{ width: 72px; height: 60px; position: relative;}
.sidebar ul li span{ width: 100%; height: 100%; display: block; text-align: center; line-height: 60px; background: none; color: #FFF; font-size: 26px; transition: all 0.3s ease; cursor: pointer; border-left: 3px solid transparent; }
.sidebar ul li span:hover{ background: #434c63; }
.sidebar ul li span.active{ background: #434c63; border-left: 3px solid #FFF; }
.sidebar ul li span i{ position: relative; left: -3px; }

    .sidebar ul li span .icon-location{ font-size: 30px; }
    .sidebar ul li span .icon-users{ font-size: 34px; }

    .sidebar ul li span.active .icon-map:before{ content: '\e80e'; }
    .sidebar ul li span.active .icon-search:before{ content: '\e817'; }
    .sidebar ul li span.active .icon-heart:before{ content: '\e809'; }
    .sidebar ul li span.active .icon-location:before{ content: '\e81f'; }
    .sidebar ul li span.active .icon-map-user:before{ content: '\e822'; }
    .sidebar ul li span.active .icon-users:before{ content: '\e821'; font-size: 30px; }
    .sidebar ul li span.active .icon-chat:before{ content: '\e825';}
    .sidebar ul li span.active .icon-settings:before{ content: '\e820'; }
    .sidebar ul li span.active .icon-truck:before{ content: '\e835'; }
    .sidebar ul li span.active .icon-routing:before{ content: '\e82a'; }


.sidebar .avatar-initials{ width: 40px; height: 40px; background: #a1a8b5; position: absolute; bottom: 20px; left: 50%; margin-left: -20px; border-radius: 100%; color: #FFF; text-align: center; line-height: 40px; font-size: 18px; cursor: pointer; }
.sidebar ul li .notification {width: 12px; height: 12px; border-radius: 100%; background: #4284F5; position: absolute; top: 12px; right: 18px;} 
.sidebar ul li.routes .notification {background: var(--color-default); } 

.sidebar .logout-popup{ position: fixed; bottom: 74px; left: 35px; max-width: 0; opacity: 0; overflow: hidden; background: #FFF; padding: 8px 15px; border-radius: 5px; transition: all 0.3s ease; }
.sidebar .logout-popup.show{ opacity: 1; max-width: 200px; min-width: 95px; }
.sidebar .logout-popup .name{ color: #000; font-size: 13px; white-space: nowrap; display: block; line-height: 1.3; margin-bottom: 3px; white-space: nowrap; }
.sidebar .logout-popup .logout{ color: #CF4727; font-size: 13px; display: block; line-height: 1.3;cursor: pointer; white-space: nowrap; }
.sidebar .logout-popup .logout:before{ content: ""; display: block; width: 12px; height: 13px; background: url("../img/ic-logout.svg"); background-size: 12px 13px; float: left; margin-right: 5px; }

@media screen and (max-height: 800px) {
    .sidebar .avatar-initials{position: relative; bottom: auto; margin-top: 20px; margin-bottom: 20px;}
}